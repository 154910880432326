exports.components = {
  "component---node-modules-gatsby-theme-abstract-blog-src-index-js": () => import("./../../../node_modules/gatsby-theme-abstract-blog/src/index.js" /* webpackChunkName: "component---node-modules-gatsby-theme-abstract-blog-src-index-js" */),
  "component---node-modules-gatsby-theme-abstract-blog-src-post-js": () => import("./../../../node_modules/gatsby-theme-abstract-blog/src/post.js" /* webpackChunkName: "component---node-modules-gatsby-theme-abstract-blog-src-post-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-free-savings-tips-js": () => import("./../../../src/pages/free-savings-tips.js" /* webpackChunkName: "component---src-pages-free-savings-tips-js" */),
  "component---src-pages-get-my-financial-score-js": () => import("./../../../src/pages/get-my-financial-score.js" /* webpackChunkName: "component---src-pages-get-my-financial-score-js" */),
  "component---src-pages-how-long-to-save-calculator-js": () => import("./../../../src/pages/how-long-to-save-calculator.js" /* webpackChunkName: "component---src-pages-how-long-to-save-calculator-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-slug-js": () => import("./../../../src/pages/{Page.slug}.js" /* webpackChunkName: "component---src-pages-page-slug-js" */),
  "component---src-pages-save-money-js": () => import("./../../../src/pages/save-money.js" /* webpackChunkName: "component---src-pages-save-money-js" */),
  "slice---src-components-footer-js": () => import("./../../../src/components/footer.js" /* webpackChunkName: "slice---src-components-footer-js" */),
  "slice---src-components-header-js": () => import("./../../../src/components/header.js" /* webpackChunkName: "slice---src-components-header-js" */)
}

